export const handleGuideSelected = guideType => {
  return {
    'consultation_guide': 'consultation-guide-modal',
    'sp_sadt_guide': 'spsadt-guide-modal',
    'hospitalization_request_guide': 'hospitalization_request_guide_modal',
    'hospitalization_summary_guide': 'hospitalization_summary_guide_modal',
    'fee_guide': 'fee_guide_modal',
    'other_expenses_guide': 'other_expenses_guide_modal',
  }[guideType]
}

export const handleGuideUpdate = guideType => {
  return {
    'App\\Models\\TissGuide\\TissGuideConsultation': 'consultation-guide-modal',
    'App\\Models\\TissGuide\\TissGuideSpSadt': 'spsadt-guide-modal',
    'App\\Models\\TissGuide\\TissGuideHospitalizationRequest': 'hospitalization_request_guide_modal',
    'App\\Models\\TissGuide\\TissGuideHospitalizationSummary': 'hospitalization_summary_guide_modal',
    'App\\Models\\TissGuide\\TissGuideFee': 'fee_guide_modal',
    'App\\Models\\TissGuide\\TissGuideOtherExpense': 'other_expenses_guide_modal',
  }[guideType]
}

export const getTypes = type => {
  type = type.split('\\').at(-1)
  const types = {
    TissGuideConsultation: 'Consulta',
    TissGuideSpSadt: 'SP/SADT',
    TissGuideHospitalizationRequest: 'Internação',
    TissGuideFee: 'Honorários',
    TissGuideHospitalizationSummary: 'Internação',
    TissGuideOtherExpense: 'Despesas'
  }
  return types[type]
}

export const procedureValue = (procedure, form, specialCases) => {
  if (!form?.pathway) {
    return {
      valueProcedure: procedure.medical_fee + procedure.operational_cost + procedure.m2_filme + procedure.anesthetic_port
    }
  }
  const pathway = parseInt(form.pathway?.value)
  if (procedure?.monocular) {
    specialCases.honorary_bilateral = 200
    specialCases.operational_cost_bilateral = specialCases.operational_cost_monocular_bilateral
    return getBilateralValue(procedure, specialCases)
  }
  const pathways = {
    1: getBilateralValue(procedure, specialCases),
    2: getSameWayValue(procedure, specialCases),
    3: getDifferentWayValue(procedure, specialCases)
  }
  return pathways[pathway]
}

export const getProcedureValue = (procedure, form, specialCases) => {
  return procedureValue(
    procedure,
    form,
    specialCases
  ).valueProcedure
}

const getBilateralValue = (procedure, specialCases) => {
  let {
    honorary_bilateral: honoraryBilateral,
  } = specialCases || 0

  const { medical_fee: medicalFee, operational_cost: operationalCost, m2_filme: m2Filme, anesthetic_port: anestheticPort } = procedure

  honoraryBilateral = honoraryBilateral ? (honoraryBilateral / 100) : 0

  return {
    valueProcedure: (medicalFee + operationalCost + m2Filme + anestheticPort) * honoraryBilateral,
    increase: honoraryBilateral
  }
}

const getSameWayValue = (procedure, specialCases) => {
  let {
    m2_same_way: m2SameWay,
  } = specialCases || 0

  const { medical_fee: medicalFee, operational_cost: operationalCost, m2_filme: m2Filme, anesthetic_port: anestheticPort } = procedure

  m2SameWay = m2SameWay ? (m2SameWay / 100) : 0

  return {
    valueProcedure: (medicalFee + operationalCost + m2Filme + anestheticPort) * m2SameWay,
    increase: m2SameWay
  }
}

const getDifferentWayValue = (procedure, specialCases) => {
  let {
    honorary_different_way: honoraryDifferentWay,
  } = specialCases || 0

  const { medical_fee: medicalFee, operational_cost: operationalCost, m2_filme: m2Filme, anesthetic_port: anestheticPort } = procedure

  honoraryDifferentWay = honoraryDifferentWay ? (honoraryDifferentWay / 100) : 0

  return {
    valueProcedure: (medicalFee + operationalCost + m2Filme + anestheticPort) * honoraryDifferentWay,
    increase: honoraryDifferentWay
  }
}
